@import 'variable';
@import 'mixin';
/* Header
-------------------------------------------------- */
.header-logo-container {
  position: relative;
  width: 20%;

  .logo-font-background {
    position: absolute;
    width: 98%;
    background-color: #FBF8EE;
    height: 98%;
    bottom: 1px;
    z-index: -1;
    right: 2px;
    opacity: .4;
  }

  @media screen and (min-width: $screen-m) {
    width: 17%;
  }

  @media screen and (min-width: $screen-xl) {
    width: 15%;
  }
}

/* Navigation
-------------------------------------------------- */
.navbar {
  z-index: 9999;
  position: absolute;
  width: 100%;
  padding: 0.5rem 0.5rem;
  
  ul {
    list-style: none;
  }
}
  
.navbar-collapse {
  $top: 46px;

  position: absolute;
  top: $top;
  right: 10px;

  @media screen and (min-width: $screen-m) {
    top: $top + 10px;
  }

  @media screen and (min-width: $screen-l) {
    top: $top + 20px;
  }
}

.nav-item {
  float: none;
  width: 210px;
  background-color: $oe-dark-gray;
  border: 1px solid $oe-light-gray;
  outline: 1px solid $oe-lighter-gray;
}

.navbar-nav .nav-link {
  color: $oe-black;
  padding: 5px 10px;
  font-size: $font-size-extra-large;
}

.nav-link {
  &:hover {
    background-color: $oe-light-gray;
  }
}

.navbar-toggler {
  padding: 0.2rem 0.35rem;
  border-color: $oe-black;
  background-color: $oe-yellow;
}

.navbar-toggler-icon {
  width: 1.0em;
  height: 1.0em;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#333' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");

  @media screen and (min-width: $screen-m) {
    width: 1.5em;
    height: 1.5em;
  }

  @media screen and (min-width: $screen-l) {
    width: 2em;
    height: 2em;
  }
}

/* Submenu
-------------------------------------------------- */
.submenu {
  list-style: none;
  
  a {
    display: block;
    width: 100%;
    height: 40px;
    padding-left: 30px;
    color: $oe-black;
  }

  .nav-link {
    padding-left: 30px;
    font-size: $font-size-large;
  }
}

/* submenu animation
-------------------------------------------------- */
.one-sub-link:hover .submenu {
  height: 40px;
}

.two-sub-link:hover .submenu {
  height: 80px;
}

.three-sub-link:hover .submenu {
  height: 120px;
}

.animation-submenu {
  height: 0;
  -webkit-transition: height .7s ease-in;
      -moz-transition: height .7s ease-in;
        -o-transition: height .7s ease-in;
          transition: height .7s ease-in;
}

/* language menu
-------------------------------------------------- */
.language-menu {
  padding: 0;
  margin-left: 18px;
  opacity: 1;
  visibility: visible;

  .nav-link {
    padding-left: 12px;
  }
  
  .activate {
    background-color: transparent;
    list-style: url(../images/gelberclip1.png);
  }
}