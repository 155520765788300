$screen-s-bs: 540px;
$screen-m: 500px;
$screen-m-bs: 720px;
$screen-l: 800px;
$screen-l-bs: 992px;
$screen-xl: 1000px;

$layout-box-size: 1200px;
$layout-article-size: 900px;

$container-space: 10px;
$box-padding: 30px;
$section-size: 60px;
$section-size-l: 120px;

$font-size-extra-small: 12px;
$font-size-small: 14px;
$font-size-normal: 18px;
$font-size-large: 20px;
$font-size-extra-large: 25px;
$font-size-super-extra-large: 35px;

$font-weight-normal: 400;
$font-weight-bold: 500;

$oe-yellow: #FFE700;
$oe-yellow-dark: #F8DF00;
$oe-black: #333;
$oe-dark-gray: #CAC8C5;
$oe-light-gray: #f2efe7;
$oe-lighter-gray: #FBF8EE;
$primary-button-bg: #177fad;
$primary-button-broder: #14729A;

$yellow-arrow: '../images/gelberclip1.png';
$yellow-clip: '../images/gelberclip.svg';
$black-clip: '../images/blackclip.svg';