@mixin transition-all-5 {
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}

@mixin triangle ($height) {
    border-top: $height solid transparent;
    border-bottom: $height solid transparent;
}